import { FunctionComponent, ReactElement } from 'react';
import { createFragmentContainer, graphql } from 'react-relay/legacy';
import classnames from 'classnames';

// components
import { Hold } from './Hold';
import { PrivateOfferItemPrices } from './PrivateOfferItemPrices';
import { SaleItemPrices } from './SaleItemPrices';
import { NetItemPrices } from './NetItemPrices';
import { RetailItemPrices } from './RetailItemPrices';
import { PriceAmount } from './PriceAmount';
import { textPriceValue } from './messages/priceMessages';
import { LocalizedSoldIcon } from 'dibs-elements/exports/LocalizedSoldIcon';
import { LocalizedUnavailableIcon } from 'dibs-elements/exports/LocalizedUnavailableIcon';
import { ClientSuspense } from 'dibs-elements/exports/ClientSuspense';

import { PRICE_TYPES, TEXT_SIZE_SMALL, TEXT_SIZE_MEDIUM, TEXT_SIZE_LARGE_HEAVY } from './constants';

// helpers
import {
    getPriceFromAmountListByCurrency,
    isValidTextOnlyPriceType,
    getRoundedPrice,
    usePdpPriceRoundingClientVariant,
} from './helpers';

// styles
import styles from './PriceDisplay.scss';

// types
import { PriceDisplay_item$data as ItemType } from './__generated__/PriceDisplay_item.graphql';
import { PriceDisplay_sku$data as SkuType } from './__generated__/PriceDisplay_sku.graphql';

type Props = {
    currency?: string;
    item: ItemType | null;
    sku?: SkuType | null;
    textSize?: typeof TEXT_SIZE_SMALL | typeof TEXT_SIZE_MEDIUM | typeof TEXT_SIZE_LARGE_HEAVY;
    useSvgIcon?: boolean;
    appendElementToPrice?: ReactElement;
    displayVatLabel?: boolean;
    roundPrice?: boolean;
    handleRoundPriceTracking?: () => void;
    showCompactedPrice?: boolean;
};

const getPriceLabel = (
    useSvgIcon: boolean,
    textType: string,
    textSize?: string
): JSX.Element | null => {
    if (useSvgIcon && [PRICE_TYPES.SOLD, PRICE_TYPES.UNAVAILABLE].includes(textType)) {
        const className = classnames(styles.icon, {
            [styles.smallText]: textSize === TEXT_SIZE_SMALL,
        });

        if (textType === PRICE_TYPES.SOLD) {
            return (
                <ClientSuspense fallback={<div className={className} />}>
                    <LocalizedSoldIcon className={className} />
                </ClientSuspense>
            );
        } else if (textType === PRICE_TYPES.UNAVAILABLE) {
            return (
                <ClientSuspense fallback={<div className={className} />}>
                    <LocalizedUnavailableIcon className={className} />
                </ClientSuspense>
            );
        }
    }

    // most basic case - return label as text
    return textPriceValue(textType);
};

export const PriceDisplayComponent: FunctionComponent<Props> = ({
    item,
    sku,
    currency = 'USD',
    textSize = TEXT_SIZE_MEDIUM,
    useSvgIcon = false,
    appendElementToPrice,
    displayVatLabel = false,
    roundPrice = false,
    handleRoundPriceTracking,
    showCompactedPrice = false,
}) => {
    const holdDisplayTime = item?.activeHold?.displayTimeToExpire || null;
    const itemPriceDisplay = sku ? sku.displayPrice?.[0] : item?.displayPrice?.[0];
    const isPdpPriceRoundingClientVariant = usePdpPriceRoundingClientVariant();

    if (!itemPriceDisplay) {
        return <div className={styles.wrapper} />;
    }

    const {
        convertedAmountList,
        originalConvertedAmountList,
        privateOfferConvertedAmountList,
        amountType,
        textType,
        quantityDisplay,
        percentageOff,
        privateOfferPercentageOff,
        showPriceLabel,
        offerExpirationDate,
        isVatIncluded,
        priceBookName,
    } = itemPriceDisplay;

    const showHold = textType === PRICE_TYPES.HOLD || textType === PRICE_TYPES.HOLD_CUSTOM;

    const amountByCurrency = getPriceFromAmountListByCurrency(convertedAmountList, currency) || 0;
    const originalAmountByCurrency =
        getPriceFromAmountListByCurrency(originalConvertedAmountList, currency) || 0;

    const price =
        isPdpPriceRoundingClientVariant && roundPrice
            ? getRoundedPrice(amountByCurrency)
            : amountByCurrency;
    const originalPrice =
        isPdpPriceRoundingClientVariant && roundPrice
            ? getRoundedPrice(originalAmountByCurrency)
            : originalAmountByCurrency;

    if (
        handleRoundPriceTracking &&
        (amountByCurrency % 1 !== 0 || originalAmountByCurrency % 1 !== 0)
    ) {
        handleRoundPriceTracking();
    }

    const percentageValue = percentageOff ? parseInt(percentageOff) : undefined;
    const privateOfferPercentageValue = privateOfferPercentageOff
        ? parseInt(privateOfferPercentageOff)
        : undefined;
    const isPriceValid = typeof price === 'number' && price > 0;
    const shouldDisplayVatLabel = !!displayVatLabel && !!isVatIncluded;

    let priceLabel = null;
    // Getting priceComponent
    let priceComponent = null;
    const sharedPriceProps = {
        price,
        originalPrice,
        currency,
        quantityDisplay,
        hasPrivateOffer: !!privateOfferConvertedAmountList,
    };

    if (isValidTextOnlyPriceType(textType)) {
        const isSold = textType === PRICE_TYPES.SOLD;
        priceLabel = getPriceLabel(useSvgIcon, textType || '', textSize);

        // Item is unavailable or has no visible price (e.g. sold, unavailable or pur)
        priceComponent = (
            <div className={styles.soldUnavailablePriceWrapper}>
                <div
                    className={classnames({
                        [styles.sold]: isSold,
                        [styles.unavailable]: textType === PRICE_TYPES.UNAVAILABLE,
                        [styles.pur]: textType === PRICE_TYPES.PUR,
                    })}
                    data-tn={`price-${isSold ? 'DLOS' : textType}-price`}
                >
                    {priceLabel}
                </div>
                {!showCompactedPrice &&
                    (!!price && !!originalPrice && isSold ? (
                        <>
                            {/* SALE PRICE */}
                            {!!price && (
                                <PriceAmount
                                    isInline
                                    type={PRICE_TYPES.RETAIL}
                                    currency={currency}
                                    amount={price}
                                    quantityDisplay={quantityDisplay}
                                    appendElementToPrice={appendElementToPrice}
                                    priceBookName={priceBookName}
                                />
                            )}
                            {/* ORIGINAL PRICE */}
                            {!!originalPrice && (
                                <PriceAmount
                                    hasSalePrice
                                    showLabel
                                    currency={currency}
                                    amount={originalPrice}
                                    quantityDisplay={quantityDisplay}
                                    hasPrivateOffer={!!privateOfferConvertedAmountList}
                                    priceBookName={priceBookName}
                                />
                            )}
                        </>
                    ) : (
                        (!!originalPrice || !!price) &&
                        textType !== PRICE_TYPES.PUR && (
                            <PriceAmount
                                isInline
                                type={
                                    amountType === PRICE_TYPES.FINAL &&
                                    //fallback to retail price if item has only original price
                                    !(amountType === PRICE_TYPES.FINAL && !!originalPrice)
                                        ? PRICE_TYPES.FINAL
                                        : PRICE_TYPES.RETAIL
                                }
                                currency={currency}
                                amount={originalPrice || price}
                                showLabel
                                quantityDisplay={quantityDisplay}
                                isUnpurchasable
                                displayVatLabel={shouldDisplayVatLabel}
                                priceBookName={priceBookName}
                            />
                        )
                    ))}
            </div>
        );
    } else if (isPriceValid && !isValidTextOnlyPriceType(textType)) {
        if (amountType === PRICE_TYPES.SALE) {
            // Item is on sale
            priceComponent = (
                <SaleItemPrices
                    {...sharedPriceProps}
                    percentageOff={percentageValue}
                    appendElementToPrice={appendElementToPrice}
                    displayVatLabel={shouldDisplayVatLabel}
                    priceBookName={priceBookName}
                    showCompactedPrice={showCompactedPrice}
                />
            );
        } else if (amountType === PRICE_TYPES.NET) {
            // Item has a net price and is being viewed by a trade user
            priceComponent = (
                <NetItemPrices
                    {...sharedPriceProps}
                    percentageOff={percentageValue}
                    displayVatLabel={shouldDisplayVatLabel}
                    priceBookName={priceBookName}
                    showCompactedPrice={showCompactedPrice}
                />
            );
        } else if (amountType === PRICE_TYPES.RETAIL) {
            // Item only has a retail price or is being viewed by a non-trade user or it's private offer original price
            priceComponent = (
                <RetailItemPrices
                    {...sharedPriceProps}
                    percentageOff={privateOfferPercentageValue}
                    showPriceLabel={!!showPriceLabel}
                    appendElementToPrice={appendElementToPrice}
                    displayVatLabel={shouldDisplayVatLabel}
                    priceBookName={priceBookName}
                />
            );
        }
    }

    // Items can have holds, but not valid price e.g. PUR items
    if (!priceComponent && !showHold) {
        return null;
    }

    let privateOffer;
    if (privateOfferConvertedAmountList) {
        const privateOfferPrice = getPriceFromAmountListByCurrency(
            privateOfferConvertedAmountList,
            currency
        );

        privateOffer = (
            <PrivateOfferItemPrices
                {...sharedPriceProps}
                price={privateOfferPrice}
                expiration={offerExpirationDate}
                showExpirationDate={!holdDisplayTime}
                displayVatLabel={shouldDisplayVatLabel}
                priceBookName={priceBookName}
            />
        );
    }

    const priceWrapperClasses = classnames(styles.wrapper, {
        [styles.smallText]: textSize === TEXT_SIZE_SMALL,
        [styles.largeHeavy]: textSize === TEXT_SIZE_LARGE_HEAVY,
    });

    const onHold = textType === PRICE_TYPES.HOLD || textType === PRICE_TYPES.HOLD_CUSTOM;
    if (onHold && showCompactedPrice) {
        privateOffer = null;
        priceComponent = null;
    }

    return (
        <div className={priceWrapperClasses} data-tn="price-wrapper">
            {/* 'On Hold For You' OR 'On Hold' */}
            {onHold && (
                <Hold
                    holdType={textType}
                    holdDisplayTime={holdDisplayTime}
                    showCompactedPrice={showCompactedPrice}
                />
            )}
            {/* show Private Offer if exists */}
            {privateOffer}
            {/* Price */}
            {priceComponent}
        </div>
    );
};

export const PriceDisplay = createFragmentContainer(PriceDisplayComponent, {
    item: graphql`
        fragment PriceDisplay_item on Item
        @argumentDefinitions(
            userId: { type: "String", defaultValue: "" }
            isTrade: { type: "Boolean", defaultValue: false }
            userCountryCode: { type: "String" }
            priceBookName: { type: "String" }
        ) {
            activeHold {
                displayTimeToExpire
            }
            displayPrice(
                isTrade: $isTrade
                buyerId: $userId
                userCountryCode: $userCountryCode
                priceBookName: $priceBookName
            ) {
                originalConvertedAmountList {
                    currency
                    amount
                }
                convertedAmountList {
                    currency
                    amount
                }
                privateOfferConvertedAmountList {
                    currency
                    amount
                }
                quantityDisplay
                percentageOff
                privateOfferPercentageOff
                textType
                amountType
                showPriceLabel
                offerExpirationDate
                isVatIncluded
                priceBookName
            }
        }
    `,
    sku: graphql`
        fragment PriceDisplay_sku on ItemSkuType
        @argumentDefinitions(
            hasSkuId: { type: "Boolean", defaultValue: false }
            userId: { type: "String", defaultValue: "" }
            isTrade: { type: "Boolean", defaultValue: false }
            userCountryCode: { type: "String" }
            priceBookName: { type: "String" }
        ) {
            ... @include(if: $hasSkuId) {
                displayPrice(
                    isTrade: $isTrade
                    buyerId: $userId
                    userCountryCode: $userCountryCode
                    priceBookName: $priceBookName
                ) {
                    originalConvertedAmountList {
                        currency
                        amount
                    }
                    convertedAmountList {
                        currency
                        amount
                    }
                    privateOfferConvertedAmountList {
                        currency
                        amount
                    }
                    quantityDisplay
                    percentageOff
                    privateOfferPercentageOff
                    textType
                    amountType
                    showPriceLabel
                    offerExpirationDate
                    isVatIncluded
                    priceBookName
                }
            }
        }
    `,
});
